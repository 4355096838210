import { Routes, Route, Navigate } from "react-router-dom";
import Landing from "../pages/Landing/Initial/index";
import Access from "../pages/Access/Initial/index";
import Dashboard from "../pages/Dashboard/Initial/index";
import Embedded from "../pages/Embedded/index"

import PrivateRoute from "../pages/PrivateRoute";


export const RoutesUrl = () => {
  <PrivateRoute>
    <Landing />
  </PrivateRoute>

  return (
    <Routes>
      <Route path="*" element={<Navigate to="/" />} />
      <Route path="/" element={<Landing />} />
  

      <Route path="/access" element={
        <PrivateRoute>
          <Access />
        </PrivateRoute>
      } />
      
      <Route path="/dashboard" element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      } />

      <Route path="/dashboard/embedded" element={
        <PrivateRoute>
          <Embedded />
        </PrivateRoute>
      } />
  
    </Routes>
  );
};
