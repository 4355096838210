import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { DashContext } from '../../../context/Dashboard/DashContext';

const CardBI = ({ card, setSelectPowerBI, setShowModal, typeUser }) => {
    const navigate = useNavigate();
    const { setSeletctPowerBI } = useContext(DashContext);


    const styleCard = {
        width: "32%",
        height: "290px",
        paddingTop: "13px",
        paddingLeft: "16px",
        paddingRight: "16px",
        borderRadius: "15px",
        background: "white",
        border: "1px solid #D7D7D7",
        marginBottom: "20px",
    }

    const buttonStyle = {
        backgroundColor: "#000",
        width: "100%",
        height: "45px",
        borderRadius: "50px",
        border: "none",
        color: "#fff",
    }

    const styleImage = {
        width: "100%",
        height: "150px",
        borderRadius: "10px",
        background: "white",
        border: "1px solid #D7D7D7",
        marginBottom: "16px",
        objectFit: "cover"
    }

    const headerCard = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        marginBottom: "15px"
    }


    return (
        <div style={styleCard}>
            <div style={headerCard}>
                <p className='textTitlePowerBI' style={{ width: "95%", height: "24px", overflow: "hidden" }}>{card.title}</p>
                <button style={{ all: "unset", cursor: "pointer" }} onClick={() => { setSelectPowerBI(card); setShowModal(true); }}>
                    <svg width="5" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="1.938" cy="2.703" fill="#000" r="1.938" />
                        <circle cx="1.942" cy="8.516" fill="#000" r="1.938" />
                        <circle cx="1.942" cy="15.297" fill="#000" r="1.938" />
                    </svg>
                </button>
            </div>
            {card?.image ?
                <img style={styleImage} src={card.image} alt='imagem do Power BI' />
                :
                <div style={{ ...styleImage, textAlign: 'center', paddingTop: "45px" }}><h1 style={{ transform: 'rotate(-15deg)' }}>Power BI</h1></div>
            }
            <button type="button" style={buttonStyle} onClick={() => { navigate("/dashboard/embedded"); setSeletctPowerBI(card); }}>Acessar BI</button>
        </div>
    )
}

export default CardBI