import React, { useContext, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import CardUser from "./CardUser";
import ModalContainer from "../components/ModalContainer";
import ModalOptions from "./ModalOptions";
import ModalFormClient from "./ModalFormClient";
import ModalFormAdmin from "./ModalFormAdmin";
import { DashContext } from "../../../context/Dashboard/DashContext";
import Pagination from "./pagination";

const TableList = ({
  filterOption,
  searchingUser,
  setNameButtom,
  setColorButtom,
  listCardSelected,
  setListCardSelected,
  modalFormAdmin,
  setModalFormAdmin,
  modalFormClient,
  setModalFormClient,
  optionModalFormAdmin,
  setOptionModalFormAdmin,
  optionModalFormClient,
  setOptionModalFormClient,
}) => {
  const {
    clients,
    onGetClient,
    onDestroyClient,
    totalPage,
    filterName,
    onGetFilterClient,
  } = useContext(DashContext);

  const [listAdmins, setListAdmins] = useState([]);

  const [globalCheked, setGlobalCheked] = useState(false);
  const [cardSelected, setCardSelected] = useState();

  const [modalOptions, setModalOpstions] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (filterOption == "Acessos admin") {
      setNameButtom("Novo usuário");
    } else if (filterOption == "Acessos clientes") {
      setNameButtom("Novo cliente");
    }
    if (globalCheked) {
      setNameButtom("Deletar todos");
      setColorButtom("red");
    } else {
      setColorButtom("var(--color-primary)");
    }
  }, [globalCheked, filterOption]);

  useEffect(() => {
    if (String(filterName).length > 0) {
      onGetFilterClient(currentPage);
    } else {
      onGetClient(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    setListAdmins(clients);
  }, [clients]);

  return (
    <>
      <div>
        <table
          className="border-table"
          style={{ textAlign: "left", width: "100%", marginTop: "2.68rem" }}
        >
          <thead>
            <tr style={{ position: "relative", top: "20px" }}>
              <td style={{ borderRight: "none" }}></td>
              <td style={{ borderRight: "none", paddingLeft: "63px" }}>
                Nome do usuário
              </td>
              <td style={{ borderRight: "none" }}>E-mail de acesso</td>
              <td style={{ borderRight: "none" }}>Empresa</td>
              <td></td>
            </tr>
          </thead>
          {listAdmins?.length &&
            listAdmins.map((item) => {
              return (
                <>
                  <CardUser
                    user={item}
                    filterOption={filterOption}
                    globalCheked={globalCheked}
                    setCardSelected={setCardSelected}
                    setModalOpstions={() => setModalOpstions(true)}
                    setOptionModalFormAdmin={() =>
                      setOptionModalFormAdmin("edit")
                    }
                    setOptionModalFormClient={() =>
                      setOptionModalFormClient("edit")
                    }
                    setNameButtom={setNameButtom}
                    setColorButtom={setColorButtom}
                    listCardSelected={listCardSelected}
                    setListCardSelected={setListCardSelected}
                  />
                  <div style={{ marginTop: "13px" }} />
                </>
              );
            })}
          <div style={{ paddingBottom: 100 }} />
        </table>
        <Pagination
          totalPages={totalPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>

      <ModalContainer
        isActive={modalOptions}
        closeModal={() => setModalOpstions(!modalOptions)}
      >
        <ModalOptions
          setModalFormAdmin={() => setModalFormAdmin(!modalFormAdmin)}
          setModalFormClient={() => setModalFormClient(!modalFormClient)}
          isActive={modalOptions}
          edit={true}
          type="delete"
          card={cardSelected}
          closeModal={() => setModalOpstions(!modalOptions)}
          filterOption={filterOption}
        />
      </ModalContainer>

      <ModalContainer
        isActive={modalFormAdmin}
        closeModal={() => setModalFormAdmin(!modalFormAdmin)}
      >
        <ModalFormAdmin
          cardSelected={cardSelected}
          closeModal={() => setModalFormAdmin(!modalFormAdmin)}
          optionModalFormAdmin={optionModalFormAdmin}
        />
      </ModalContainer>

      <ModalContainer
        isActive={modalFormClient}
        closeModal={() => setModalFormClient(!modalFormClient)}
      >
        <ModalFormClient
          cardSelected={cardSelected}
          closeModal={() => setModalFormClient(!modalFormClient)}
          optionModalFormClient={optionModalFormClient}
        />
      </ModalContainer>
    </>
  );
};

export default TableList;
