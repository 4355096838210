import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./styles/animations.css";
import "./styles/hover.css";
import "./styles/active.css";
import { RoutesUrl } from "./routes/routes";
import { AuthValidation } from "./context/Auth/AuthContext";
import { ToastContainer } from "react-toastify";
import { DashValidation } from "./context/Dashboard/DashContext";

const App = () => {
  return (
    <>
      <DashValidation>
        <AuthValidation>
          <RoutesUrl />
        </AuthValidation>
      </DashValidation>
      <ToastContainer />
    </>
  );
};

export default App;
