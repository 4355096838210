import React from "react";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import Sidebar from "../../../components/Sidebar";
import HeaderDashboard from "../../../components/HeaderDashboard";
import AccessManagementTable from "../components/AccessManagementTable";

const Access = () => {
  return (
    <ThowColumLayout
      page={"Access"}
      colum1={<Sidebar page="Access" />}
      colum2={
        <>
          <HeaderDashboard name="Gerenciar acessos" btnBack={false} />
          <AccessManagementTable />
        </>
      }
    >
    </ThowColumLayout>
  );
};

export default Access;
