import React from "react";
const ThowColumLayoutLogin = ({ colum1, background }) => {
  const container = {
    background: background,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
  };

  return (
    <div style={container}>
      <div>{colum1}</div>
    </div>
  );
};

export default ThowColumLayoutLogin;
