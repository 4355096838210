import { useState } from "react";
import TableList from "./TableList";
import SelectSessionButtons from "./SelectSessionButtons";
import InputSearchAndButton from "../../../components/InputSearchAndButton";
import InputSearchAndButtonAccess from "./InputSearchAndButtonAccess";

const AccessManagementTable = () => {
  const [btnSelect, setBtnSelect] = useState("Acessos admin");
  const [searchingUser, setSearchingUser] = useState("");

  const [nameButtom, setNameButtom] = useState("Novo usuário");
  const [colorButtom, setColorButtom] = useState("var(--color-primary)");

  const [listCardSelected, setListCardSelected] = useState([]);

  const [modalFormAdmin, setModalFormAdmin] = useState(false);
  const [optionModalFormAdmin, setOptionModalFormAdmin] = useState("create");
  const [modalFormClient, setModalFormClient] = useState(false);
  const [optionModalFormClient, setOptionModalFormClient] = useState("create");

  /* STYLES */
  const container = {
    maxWidth: "100%",
    paddingTop: "2.6875rem",
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  };

  const containerSearch = {
    display: "flex",
    alignItems: "center",
    gap: "26px",
    marginTop: "45px",
  };

  return (
    <div className="containerBox" style={container}>
      <div style={containerSearch}>
        <InputSearchAndButtonAccess
          value={searchingUser}
          onChange={setSearchingUser}
          nameButton={nameButtom}
          colorButtom={colorButtom}
          listCardSelected={listCardSelected}
          setModalFormAdmin={setModalFormAdmin}
          setOptionModalFormAdmin={setOptionModalFormAdmin}
          setModalFormClient={setModalFormClient}
          setOptionModalFormClient={setOptionModalFormClient}
        />
      </div>

      <TableList
        filterOption={btnSelect}
        searchingUser={searchingUser}
        setNameButtom={setNameButtom}
        setColorButtom={setColorButtom}
        listCardSelected={listCardSelected}
        setListCardSelected={setListCardSelected}
        modalFormAdmin={modalFormAdmin}
        setModalFormAdmin={setModalFormAdmin}
        modalFormClient={modalFormClient}
        setModalFormClient={setModalFormClient}
        optionModalFormAdmin={optionModalFormAdmin}
        setOptionModalFormAdmin={setOptionModalFormAdmin}
        optionModalFormClient={optionModalFormClient}
        setOptionModalFormClient={setOptionModalFormClient}
      />
    </div>
  );
};

export default AccessManagementTable;
