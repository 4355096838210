import React from 'react'

const Button = ({width,height,background,borderRadius,marginTop,name,border,disabled,onClick,color,padding,className,selectBtn}) => {

    const styleButton = {

        width: !className && width,
        height:height,
        background: selectBtn == name ? background : "#fff",
        borderRadius:borderRadius,
        marginTop:marginTop,
        border:border,
        cursor:"pointer",
        color: selectBtn == name ? color : background,
        padding: padding
    }

    const styleButtonDisabled = {

        width:width,
        height:height,
        background:"#5c5b5b",
        borderRadius:borderRadius,
        marginTop:marginTop,
        border:border,
    }

    return(
        disabled == true 
        
        ?
        <button className={`${className} textPrimaryButton`} disabled={disabled}  style={styleButtonDisabled}>{name}</button>

        :

        <button className={`${className} textPrimaryButton`} disabled={disabled} onClick={onClick} style={styleButton}>{name}</button>

    )

}

export default Button