import Button from "../pages/Access/components/Button";
import InputSearch from "../pages/Access/components/InputSearch";
import Select from "react-dropdown-select";

const InputSearchAndButton = ({
  widthInput,
  heightInput,
  nameButton,
  colorButtom,
  value,
  onChange,
  setModalFormAdmin,
  setOptionModalFormAdmin,
  setModalEdit,
  setFilter,
  options,
}) => {
  const container = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const handleClick = () => {
    if (nameButton == "Novo usuário") {
      setOptionModalFormAdmin("create");
      setModalFormAdmin(true);
    } else if (nameButton === "Novo aceso BI") {
      setModalEdit(true);
    }
  };

  return (
    <div className="containerBox" style={container}>
      <div style={{ display: "flex" }}>
        <InputSearch
          width={widthInput}
          height={heightInput}
          value={value}
          onChange={onChange}
        />
        {options && (
          <Select
            style={{
              marginLeft: "45px",
              height: "45px",
              paddingLeft: "20px",
              paddingRight: "15px",
              border: "1px solid #d7d7d7",
              width: "200px",
              borderRadius: "60px",
              backgroundColor: "#fff",
            }}
            color="#000"
            placeholder="Selecione"
            dropdownHeight={"150px"}
            searchable={true}
            options={options}
            labelField="business"
            valueField="business"
            isSearchable={true}
            clearable={true}
            noDataLabel="Nenhuma empresa"
            onChange={(value) => {
              if (value.length === 0) {
                setFilter("");
              } else {
                setFilter(value);
              }
            }}
          />
        )}
      </div>
      <Button
        width="195px"
        height="45px"
        background={colorButtom}
        borderRadius="60px"
        marginTop="0"
        name={nameButton}
        color={"var(--white)"}
        onClick={handleClick}
        selectBtn={nameButton}
        border="none"
      />
    </div>
  );
};

export default InputSearchAndButton;
