import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import InputSearchAndButton from "../../../components/InputSearchAndButton";
import close from "../../../images/svg/close.svg"
import ModalPowerBI from "../components/ModalPowerBI";
import CardBI from "../components/CardBI"

import ThowColumLayout from "../../../layouts/ThowColumLayout";
import Sidebar from "../../../components/Sidebar";
import HeaderDashboard from "../../../components/HeaderDashboard";
import api from "../../../services/api";


const Dashboard = () => {
  const [showModal, setShowModal] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [selectPowerBI, setSelectPowerBI] = useState({});
  const [updateDashboards, setUpdateDashboards] = useState(false);
  const [dashboards, setDashboards] = useState([]);
  const [business, setBusiness] = useState([]);
  const [filter, setFilter] = useState([{ title: "", business: "" }]);
  const [searchingUser, setSearchingUser] = useState("");
  const [selectBusiness, setSelectBussines] = useState([]);
  const [nameButtom, setNameButtom] = useState("Novo aceso BI");
  const [colorButtom, setColorButtom] = useState("var(--color-primary)");


  const containerSearch = {
    display: "flex",
    alignItems: "center",
    gap: "26px",
    marginTop: "25px",
    marginBottom: "25px",
  };

  const container = {
    maxWidth: "100%",
    paddingTop: "2.6875rem",
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  };

  const btnStyleRemove = {
    width: "82px",
    height: "37px",
    borderRadius: "50px",
    border: "none",
    background: "#FFE1E1"
  }


  useEffect(() => {
    getDashboards();
  }, [updateDashboards])

  const getDashboards = async () => {
    try {
      const response = await api.get("/dashboard");

      if (response.status === 200) {
        setDashboards(response.data);
      }

    } catch (error) {
      console.log("Erro na busca por dados!")
    }
  }

  useEffect(() => {
    getBusiness();
  }, [])

  const getBusiness = async () => {
    try {
      const response = await api.get("/client/business");

      if (response.status === 200) {
        setBusiness(response.data);
      }

    } catch (error) {
      console.log("Erro na busca por dados")
    }
  }

  const handleDelete = async () => {
    await api.delete(`/dashboard/delete/${selectPowerBI.id}`);
    setModalDelete(false);
    setUpdateDashboards(prevState => !prevState);
    toast.success("Excluido com sucesso!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    setSelectPowerBI({});
  }


  return (
    <ThowColumLayout
      page={"Dashboard"}
      colum1={<Sidebar page="Dashboard" />}
      colum2={
        <>
          <HeaderDashboard name="Meus Power BI" />
          <div style={container}>
            <div style={containerSearch}>
              <InputSearchAndButton
                value={searchingUser}
                onChange={setSearchingUser}
                nameButton={nameButtom}
                colorButtom={colorButtom}
                options={business}
                setFilter={setFilter}
                filter={filter}
                setModalEdit={setModalEdit}
              />
            </div>
            <div className="containerBox" style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", marginTop: "20px", gap: "2%", }}>
              {(filter && dashboards) ? dashboards
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .filter((user) => user.title.toLowerCase().includes(searchingUser.toLowerCase()))
                .filter((e) => filter ? e.business.includes(filter[0]?.business) : false)
                .map((card, index) => (
                  <CardBI key={index} card={card} setSelectPowerBI={setSelectPowerBI} setShowModal={setShowModal} />
                ))
                :
                dashboards
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .filter((user) => user.title.toLowerCase().includes(searchingUser.toLowerCase()))
                  .map((card, index) => (
                    <CardBI key={index} card={card} setSelectPowerBI={setSelectPowerBI} setShowModal={setShowModal} />
                  ))
              }
            </div>

            <Modal
              dialogClassName="modal-filter-option"
              show={showModal}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              scrollable={true}
            >
              <Modal.Header style={{ marginBottom: "-15px" }}>
                <div style={{ display: "flex", height: "30px", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                  <p className="textHeaderModalUser">Ações</p>
                  <div onClick={() => { setShowModal(false); setSelectPowerBI({}) }} style={{ cursor: "pointer", width: "20px", textAlign: "center" }}>
                    <img src={close} alt='icone de fechar' />
                  </div>
                </div>
              </Modal.Header>
              <Modal.Body style={{ display: "flex", flexDirection: "column", height: "240px" }}>
                <button onClick={() => { setModalEdit(true); setShowModal(false) }} style={{ border: "none", backgroundColor: "white", color: "black", borderBottom: `1px solid #989898`, height: "60px", width: "100%" }}>
                  <b>Editar acesso BI</b>
                </button>
                <button onClick={() => { setModalDelete(true); setShowModal(false) }} style={{ border: "none", backgroundColor: "white", color: "black", borderBottom: `1px solid #989898`, height: "60px" }}>
                  <b style={{ color: "#EA0000" }}>Excluir acesso BI</b>
                </button>
              </Modal.Body>
            </Modal>

            <Modal
              dialogClassName="modal-filter-option"
              show={modalDelete}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              scrollable={true}
            >
              <Modal.Header style={{ marginBottom: "-15px" }}>
                <div style={{ display: "flex", height: "30px", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "180px" }}>
                    <div onClick={() => { setModalDelete(false); setSelectPowerBI({}); }} style={{ cursor: "pointer", width: "20px", textAlign: "center" }}>
                      <img src={close} alt='icone de fechar' />
                    </div>
                    <p className="textHeaderModalUser">Excluir acesso BI</p>
                  </div>
                  <button style={btnStyleRemove} onClick={handleDelete}>
                    <b style={{ color: "red", textAlign: "center" }}>
                      Excluir
                    </b>
                  </button>
                </div>
              </Modal.Header>
              <Modal.Body style={{ display: "flex", flexDirection: "column", height: "240px", marginLeft: "-15px", marginRight: "-15px", textAlign: "center", justifyContent: "center" }}>
                <p>Tem certeza que deseja excluir o acesso do power BI <b>{selectPowerBI.title}</b> ?</p>
              </Modal.Body>
            </Modal>

            {/* MODAL DE EDICAO */}
            <ModalPowerBI business={business} selectBusiness={selectBusiness} setSelectBussines={setSelectBussines} setShowModal={setModalEdit} showModal={modalEdit} title={"Editar acesso BI"} data={selectPowerBI} setSelectPowerBI={setSelectPowerBI} setUpdateDashboards={setUpdateDashboards} />
          </div>
        </>
      }
    >
    </ThowColumLayout>
  );
};

export default Dashboard;
