import firebase from "firebase/compat/app";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB5RXqMXyNGbLEljw5PEmq_Wuw_KR0RTx0",
  authDomain: "play-tele.firebaseapp.com",
  projectId: "play-tele",
  storageBucket: "play-tele.appspot.com",
  messagingSenderId: "12103476472",
  appId: "1:12103476472:web:015920b218b521790fc70d",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export default app;
