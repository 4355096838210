import { toast } from "react-toastify";
import { DashContext } from "../../../context/Dashboard/DashContext";
import Input from "../../Landing/components/Input";
import Button from "./Button";
import ImageUser from "./ImageUser";
import { useContext, useEffect, useState } from "react";

const ModalFormAdmin = ({ closeModal, cardSelected, optionModalFormAdmin }) => {
  const { clientDetails, onUpdateClient, onCreateClient } =
    useContext(DashContext);
  const [picture, setPicture] = useState("");
  const [name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [business, setBusiness] = useState("");
  const [typeAcess, setTypeAcess] = useState("Administrativo");

  const container = {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    width: "600px",
    boxShadow: "0px 0px 13px -3px #00000033",
    border: "1px solid transparent",
    borderRadius: "20px",
  };

  const headerOption = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #D7D7D7",
    padding: "15px 18px 18px",
  };

  const content = {
    width: "100%",
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "14px",
  };

  const boxTextDelete = {
    maxHeight: "510px",
    display: "flex",
    alignItems: "center",
    padding: "18px",
    flexDirection: "column",
    overflowY: "auto",
  };

  const updateClient = () => {
    const data = {
      image: picture,
      name: name,
      mail: email,
      business: business,
    };

    if (password) {
      data.password = password;
    }

    onUpdateClient(data);
    closeModal();
  };

  const createClient = () => {
    const data = {
      image: picture,
      name: name,
      mail: email,
      type: typeAcess,
      business: business,
      password: password,
    };

    if (!password || !email) {
      return toast.error(`Email e senha são obrigatórios`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    onCreateClient(data);
    closeModal();
  };

  useEffect(() => {
    if (optionModalFormAdmin === "edit") {
      setPicture(clientDetails?.image);
      setName(clientDetails?.name);
      setEmail(clientDetails?.mail);
      setPassword(clientDetails?.password);
      setBusiness(clientDetails?.business);
    }
  }, [clientDetails]);

  return (
    <div className="modalEdit" style={container}>
      <div style={headerOption}>
        <div
          style={{
            display: "flex",
            gap: "27px",
            alignItems: "center",
          }}
        >
          <div
            onClick={closeModal}
            style={{
              cursor: "pointer",
            }}
          >
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.48406 6.36766L12.4871 1.35916C12.7976 1.04823 12.7976 0.544109 12.4871 0.2332C12.1765 -0.0777335 11.673 -0.0777335 11.3624 0.2332L6.35997 5.24222L1.35752 0.2332C1.04696 -0.0777335 0.543453 -0.0777335 0.232919 0.2332C-0.0776148 0.544134 -0.0776396 1.04825 0.232919 1.35916L5.23589 6.36766L0.232919 11.3762C-0.0776396 11.6871 -0.0776396 12.1912 0.232919 12.5021C0.543478 12.8131 1.04699 12.8131 1.35752 12.5021L6.35997 7.4931L11.3624 12.5021C11.673 12.8131 12.1765 12.8131 12.487 12.5021C12.7976 12.1912 12.7976 11.6871 12.487 11.3762L7.48406 6.36766Z"
                fill="black"
              />
            </svg>
          </div>
          {optionModalFormAdmin === "edit" ? (
            <p className="textModalTitleOption">Editar usuário</p>
          ) : (
            <p className="textModalTitleOption">Novo usuário</p>
          )}
        </div>

        {optionModalFormAdmin === "edit" ? (
          <Button
            width="181px"
            height="33px"
            background="var(--color-primary)"
            borderRadius="60px"
            marginTop="0"
            name="Editar"
            selectBtn={"Editar"}
            onClick={updateClient}
            border="none"
          />
        ) : (
          <Button
            width="181px"
            height="33px"
            background="var(--color-primary)"
            borderRadius="60px"
            marginTop="0"
            name="Criar"
            selectBtn={"Criar"}
            onClick={createClient}
            border="none"
          />
        )}
      </div>
      <div style={boxTextDelete}>
        <ImageUser image={picture} setPicture={setPicture} />
        <div style={content}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              topEye="46px"
              rightEye="10px"
              color="black"
              width="265px"
              height="45px"
              name="Nome do usuário"
              type="text"
            />
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              topEye="46px"
              rightEye="10px"
              color="black"
              width="265px"
              height="45px"
              name="E-mail de acesso"
              type="text"
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Input
              value={business}
              onChange={(e) => setBusiness(e.target.value)}
              topEye="46px"
              rightEye="10px"
              color="black"
              width="265px"
              height="45px"
              name="Empresa"
              type="text"
            />
            <Input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              topEye="46px"
              rightEye="10px"
              color="black"
              width="265px"
              height="45px"
              name="Senha"
              type="password"
            />
          </div>

          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label
              for="perfil"
              className="textPrimaryLabelInput"
              style={{ marginBottom: "15px" }}
            >
              Tipo de usuário
            </label>
            <select
              className="textPrimaryInput"
              value={typeAcess}
              onChange={(text) => setTypeAcess(text.target.value)}
              id="perfil"
              style={{
                width: "47%",
                height: "50px",
                borderRadius: "10px",
                borderColor: "#d7d7d7",
                color: "#000",
              }}
            >
              <option style={{ color: "black" }} value="Administrativo">
                Adm Cliente
              </option>
              <option style={{ color: "black" }} value="Visualizador">
                Visualizador Cliente
              </option>
              <option style={{ color: "black" }} value="Admin Telematics">
                Adm Play Telematics
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFormAdmin;
