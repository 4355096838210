/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ThowColumLayoutLogin from "../../../layouts/ThowColumLayoutLogin";
import companyIcon from "../../../images/svg/companyIcon.svg";
import PowerBi from "../../../images/png/microsoft_powerbi_logo_icon_169958.png";
import { AuthContext } from "../../../context/Auth/AuthContext";
import Button from "../components/Button";
import Input from "../components/Input";
import { toast } from "react-toastify";

const Landing = () => {
  const { onSubmitFormLogin } = useContext(AuthContext);

  const [mail, setMail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();

  /* MESSAGE ERROS */
  const [mailError, setMailError] = useState();

  /* BTN STATE */
  const [btnState, setBtnState] = useState();

  /* STYLES */
  const container = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const boxFooterButton = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "10px",
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const getLogin = async () => {
    const validMail = validateEmail(mail);

    if (validMail == false) {
      setMailError("email inválido");
      setBtnState(true);
    } else {
      setMailError();
      setBtnState(false);
    }

    if (mail != undefined && password != undefined && validMail != false) {
      const data = {
        email: mail,
        password: password,
      };
      onSubmitFormLogin(data);
    } else {
      toast.error("Os campos de e-mail e senha são obrigatórios", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    if (mail != undefined) {
      const validMail = validateEmail(mail);

      if (validMail == false) {
        setMailError("email inválido");
        setBtnState(true);
      } else {
        setMailError();
        setBtnState(false);
      }
    }
  }, [mail]);

  return (
    <ThowColumLayoutLogin
      page={"Landing"}
      colum1={
        <div>
          <div style={container}>
            <img src={companyIcon} style={{ width: "450px" }} />
            <h2 className="textPrimaryLanding">Login administrativo</h2>

            <Input
              name="E-mail"
              error={mailError}
              onChange={(e) => setMail(e.target.value)}
              placeholder="Insira seu e-mail"
              type={"text"}
            />
            <Input
              name="Senha"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Insira sua senha"
              type={"password"}
            />

            <div style={boxFooterButton}>
              <Button
                width="100%"
                height="48px"
                background="var(--color-primary)"
                borderRadius="60px"
                marginTop="0"
                name="Entrar"
                border="none"
                disabled={btnState}
                onClick={() => getLogin()}
              />
            </div>
            {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        > */}
            <img
              src={PowerBi}
              style={{
                width: "450px",
              }}
              alt="logo power bi"
            />
          </div>
        </div>
        // </div>
      }
    ></ThowColumLayoutLogin>
  );
};

export default Landing;
