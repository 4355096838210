import React from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import api from "../services/api";
import { useEffect } from "react";
import { useState } from "react";
import { BigHead } from "@bigheads/core";

const HeaderDashboard = ({ name, page, adm, btnBack, type }) => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: 1326 });
  const [userName, setUserName] = useState("");

  const [picture, setPicture] = useState("");

  const getProfile = async () => {
    try {
      const response = await api.get("/admin/profile/one");
      if (response.data) {
        const nameUser = response.data.name?.split(" ")[0];
        setUserName(nameUser);
        setPicture(response.data.picture);
      }
    } catch (error) {
      console.log("Erro na busca por dados!");
    }
  };

  const exit = async () => {
    if (adm) {
      localStorage.clear();
      navigate("/login/admin");
    } else {
      localStorage.clear();
      navigate("/");
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  const headerStyle = {
    display: "flex",
    paddingTop: "2.6875rem",
    paddingLeft: isDesktop ? "0" : "3.375rem",
    justifyContent: "space-between",
    zIndex: "1",
    width: !isDesktop && "95%",
  };

  const userInfo = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "1.1rem",
    width: "234px",
    background: "white",
    border: "1.9px solid #d7d7d7",
    borderRadius: "60px",
    padding: "6px",
    paddingLeft: "20px",
  };

  return (
    <div
      className="boxContainerHeader"
      style={{
        width: "100%",
        background: "#F9F9F9",
      }}
    >
      <div className="containerHeader" style={headerStyle}>
        {page == "addStock" ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={"backIcon"}
              style={{
                position: "relative",
                top: "-8px",
                right: "27px",
                cursor: "pointer",
              }}
              onClick={() => (window.location.href = "/access/manager")}
            />
            <p className="textPrimaryHeaderDashboard">{name}</p>
          </div>
        ) : (
          <div
            style={{
              transform: "translateY(4px)",
              display: "flex",
              alignItems: "center",
              gap: "27px",
            }}
          >
            {btnBack && (
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="1"
                  y="1.02271"
                  width="27.17"
                  height="27.1826"
                  rx="13.585"
                  fill="var(--color-primary)"
                  stroke="var(--color-primary)"
                  strokeWidth="2"
                />
                <mask id="path-2-inside-1_475_14254" fill="white">
                  <path d="M17.0266 18.393L13.1839 14.5401L17.0266 10.6871L15.8436 9.50354L10.8096 14.5401L15.8436 19.5766L17.0266 18.393Z" />
                </mask>
                <path
                  d="M17.0266 18.393L18.4411 19.8069C19.2211 19.0265 19.2218 17.7619 18.4427 16.9807L17.0266 18.393ZM13.1839 14.5401L11.7678 13.1277C10.9893 13.9084 10.9893 15.1718 11.7678 15.9524L13.1839 14.5401ZM17.0266 10.6871L18.4427 12.0994C19.2218 11.3182 19.2211 10.0536 18.4411 9.27326L17.0266 10.6871ZM15.8436 9.50354L17.2581 8.08968C16.883 7.71439 16.3742 7.50354 15.8436 7.50354C15.313 7.50354 14.8041 7.71439 14.429 8.08968L15.8436 9.50354ZM10.8096 14.5401L9.395 13.1262C8.61443 13.9072 8.61443 15.1729 9.395 15.9539L10.8096 14.5401ZM15.8436 19.5766L14.429 20.9904C14.8041 21.3657 15.313 21.5766 15.8436 21.5766C16.3742 21.5766 16.883 21.3657 17.2581 20.9904L15.8436 19.5766ZM18.4427 16.9807L14.6 13.1277L11.7678 15.9524L15.6105 19.8053L18.4427 16.9807ZM14.6 15.9524L18.4427 12.0994L15.6105 9.2748L11.7678 13.1277L14.6 15.9524ZM18.4411 9.27326L17.2581 8.08968L14.429 10.9174L15.612 12.101L18.4411 9.27326ZM14.429 8.08968L9.395 13.1262L12.2241 15.9539L17.2581 10.9174L14.429 8.08968ZM9.395 15.9539L14.429 20.9904L17.2581 18.1627L12.2241 13.1262L9.395 15.9539ZM17.2581 20.9904L18.4411 19.8069L15.612 16.9791L14.429 18.1627L17.2581 20.9904Z"
                  fill="white"
                  mask="url(#path-2-inside-1_475_14254)"
                />
              </svg>
            )}

            <p style={{}} className="textPrimaryHeaderDashboard">
              {name}
            </p>
          </div>
        )}

        <div className="dropdown">
          <button
            style={userInfo}
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div style={{ position: "relative", right: "13px" }}>
              {!picture ? (
                <div
                  style={{
                    width: "35px",
                    height: "35px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: "45px",
                      height: "35px",
                      position: "absolute",
                      top: "-9px",
                      left: "-10px",
                      borderRadius: "100%",
                    }}
                  >
                    <BigHead
                      accessory="shades"
                      body="chest"
                      circleColor="blue"
                      clothing="tankTop"
                      clothingColor="black"
                      eyebrows="angry"
                      eyes="wink"
                      facialHair="mediumBeard"
                      graphic="vue"
                      hair="short"
                      hairColor="black"
                      hat="none"
                      hatColor="green"
                      lashes="false"
                      lipColor="purple"
                      mask="true"
                      faceMask="true"
                      mouth="open"
                      skinTone="brown"
                    />
                  </div>
                </div>
              ) : (
                <img
                  src={picture}
                  style={{
                    width: "35px",
                    height: "35px",
                    border: "1px solid #d7d7d7",
                    borderRadius: "100%",
                    objectFit: "contain",
                  }}
                />
              )}
            </div>
            <p className="textClientInfoHeader">{userName}</p>

            <svg
              width="8"
              height="8"
              viewBox="0 0 8 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.182668 1.78582C0.0688405 1.57763 0.0688405 1.32582 0.182668 1.11763C0.446809 0.634515 1.14065 0.634515 1.40479 1.11763L2.41937 2.97328C3.12207 4.25852 4.96793 4.25852 5.67064 2.97327L6.68521 1.11763C6.94935 0.634515 7.64319 0.634515 7.90733 1.11763C8.02116 1.32582 8.02116 1.57763 7.90733 1.78582L6.67725 4.03563C5.53942 6.11672 2.55058 6.11672 1.41275 4.03563L0.182668 1.78582Z"
                fill="black"
              />
            </svg>
          </button>

          <div
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
            style={{ width: "234px" }}
          >
            <a className="dropdown-item" onClick={() => exit()} href="#">
              Sair
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderDashboard;
