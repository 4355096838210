import React, { useContext, useEffect, useState } from "react";
import treePoints from "../../../images/svg/treePoints.svg";
import { DashContext } from "../../../context/Dashboard/DashContext";
import imageDefaultUser from "../../../images/svg/imageDefaultUser.svg";

const CardUser = ({
  user,
  filterOption,
  globalCheked,
  setCardSelected,
  setModalOpstions,
  setOptionModalFormClient,
  setOptionModalFormAdmin,
  setNameButtom,
  setColorButtom,
  listCardSelected,
  setListCardSelected,
}) => {
  const { setClientId } = useContext(DashContext);
  const [checkedCard, setCheckedCard] = useState(false);

  const updateListUsers = () => {
    if (checkedCard) {
      const newArray = listCardSelected.filter((elm) => {
        return elm !== user.id;
      });
      setListCardSelected(newArray);
    } else {
      setListCardSelected([...listCardSelected, user.id]);
    }
  };

  useEffect(() => {
    setCheckedCard(globalCheked);
  }, [globalCheked]);

  useEffect(() => {
    if (checkedCard) {
      setNameButtom("Deletar usuário");
      setColorButtom("red");
    }
    if (listCardSelected.length == 0) {
      setColorButtom("var(--color-primary)");
    }
  }, [checkedCard]);

  return (
    <>
      <tbody style={{ position: "relative", top: "20px" }}>
        <tr style={{ position: "relative", top: "20px", border: "none" }}>
          <td style={{ borderRight: "none" }}>
            {/* <input
                type="checkbox"
                checked={checkedCard}
                onChange={() => {
                  setCheckedCard(!checkedCard);
                  updateListUsers();
                }}
                style={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "3px",
                }}
              /> */}
          </td>
          <td style={{ borderRight: "none" }}>
            <div style={{ display: "flex", position: "relative", top: "9px" }}>
              {!user.image ? (
                <div
                  style={{
                    width: "45px",
                    height: "35px",
                    position: "relative",
                    top: "-17px",
                    left: "-5px",
                    borderRadius: "100%",
                  }}
                >
                  <img
                    src={imageDefaultUser}
                    style={{
                      width: "35px",
                      height: "35px",
                      position: "relative",
                      top: "10px",
                      left: "0",
                      borderRadius: "100px",
                      border: "1px solid #000",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <img
                    src={user.image}
                    style={{
                      width: "35px",
                      height: "35px",
                      position: "relative",
                      top: "-8px",
                      left: "-5px",
                      borderRadius: "100px",
                      border: "1px solid #000",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                </div>
              )}
              <p
                style={{
                  borderRight: "none",
                  color: "black",
                  position: "relative",
                  left: "10px",
                }}
              >
                {user.name}
              </p>
            </div>
          </td>
          <td style={{ borderRight: "none", color: "black" }}>{user.mail}</td>
          <td style={{ borderRight: "none", color: "black" }}>
            {user.business}
          </td>
          <td
            onClick={() => {
              setClientId(user?.id);
              setCardSelected(user);
              setModalOpstions();
              setOptionModalFormAdmin();
            }}
            style={{ cursor: "pointer" }}
          >
            <img src={treePoints} />
          </td>
        </tr>
      </tbody>
    </>
  );
};

export default CardUser;
