import React, { useState, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";

import Sidebar from "../../components/Sidebar";
import HeaderDashboard from "../../components/HeaderDashboard";
import ThowColumLayout from "../../layouts/ThowColumLayout";
import { DashContext } from "../../context/Dashboard/DashContext";

const Embedded = () => {
  const { selectPowerBI } = useContext(DashContext);

  return (
    <ThowColumLayout
      page={"Dashboard"}
      colum1={<Sidebar page="Dashboard" />}
      colum2={
        <>
          <HeaderDashboard name="Meus Power BI" />

          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "95%", height: "90%", margin: "0 auto", marginTop: "30px", border: "1px solid #d7d7d7", borderRadius: "12px" }}>
            <iframe title={selectPowerBI.title} width="95%" height="90%" src={selectPowerBI.link} frameborder="1" allowFullScreen="true"></iframe>
          </div>
        </>
      }
    >
    </ThowColumLayout>
  );
};

export default Embedded;
