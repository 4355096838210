import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";

export const AuthContext = createContext({});

export const AuthValidation = ({ children }) => {
  const [checkTerms, setCheckTerms] = useState(false);
  const [email, setEmail] = useState("email@mail.com");
  const navigate = useNavigate();

  /* Login */
  const onSubmitFormLogin = async (data) => {
    try {
      const response = await api.post("/session/admin", data);
      const { token } = response.data;

      try {
        localStorage.clear();
        localStorage.setItem("@PLAY_TELEMATICS_TOKEN:", token);
        navigate("/dashboard", { replace: true });
      } catch (err) {
        const message = err.response.data.error;
        toast.error(`${message}`, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      const message = err.response.data.error;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <AuthContext.Provider
        value={{
          checkTerms,
          setCheckTerms,
          email,
          onSubmitFormLogin,
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};
