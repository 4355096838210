import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";

export const DashContext = createContext({});

export const DashValidation = ({ children }) => {
  const [clients, setClients] = useState(false);
  const [clientId, setClientId] = useState("");
  const [clientDetails, setClientDetails] = useState({});

  const [totalPage, setTotalPage] = useState();
  const [filterName, setFilterName] = useState("");
  const [selectPowerBI, setSeletctPowerBI] = useState({});

  const navigate = useNavigate();

  /* LIST */
  const onGetClient = async (page) => {
    try {
      const response = await api.get(`/client/?page=${page}`);

      setClients(response.data.data);
      setTotalPage(response.data.totalPages);
    } catch (err) {
      const message = err.response.data.error;
      console.log(message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  /* LIST FILTER */
  const onGetFilterClient = async (page) => {
    try {
      if (filterName.length > 0) {
        const response = await api.get(
          `/client/filter/${filterName}?page=${page || 1}`
        );

        setClients(response.data.data);
        setTotalPage(response.data.totalPages);
      } else {
        onGetClient(1);
      }
    } catch (err) {
      const message = err.response.data.message;
      console.log(message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  /* DESTROY */
  const onDestroyClient = async () => {
    try {
      await api.delete(`/client/${clientId}`);

      onGetClient(1);

      toast.success("Conta deletada com sucesso", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      const message = err.response.data.message;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  /* GET BY ID */
  const onGetByIdClient = async () => {
    try {
      const response = await api.get(`/client/${clientId}`);

      setClientDetails(response.data);
    } catch (err) {
      const message = err.response.data.message;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  /* UPDATE CLIENT */
  const onUpdateClient = async (data) => {
    try {
      await api.patch(`/client/${clientId}`, data);

      onGetClient(1);
    } catch (err) {
      const message = err.response.data.message;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  /* CREATE CLIENT */
  const onCreateClient = async (data) => {
    try {
      await api.post(`/client`, data);

      onGetClient(1);
    } catch (err) {
      const message = err.response.data.message;
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <DashContext.Provider
        value={{
          clients,
          onGetClient,
          onDestroyClient,
          setClientId,
          onGetByIdClient,
          clientDetails,
          onUpdateClient,
          onCreateClient,
          totalPage,
          onGetFilterClient,
          filterName,
          setFilterName,
          selectPowerBI,
          setSeletctPowerBI,
        }}
      >
        {children}
      </DashContext.Provider>
    </>
  );
};
