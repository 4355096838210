import React from "react";

import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  /* --- Add route protection when the project is finalized --- */

  // const token = localStorage.getItem("@PLAY_TELEMATICS_TOKEN:");
  // return token != undefined ? children : <Navigate to="/" />;

  return children;
};

export default PrivateRoute;
